import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  db,
  facebookProvider,
  githubProvider,
  googleProvider,
  handleSocialSignIn,
  yahooProvider,
} from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { BsFacebook, BsGithub, BsGoogle, BsLinkedin } from "react-icons/bs";
import { FaYahoo } from "react-icons/fa";

const SignUp = () => {
  const [phoneNumber, setPhoneNumber] = useState("+212");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleAllgood = async (e) => {
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !cPassword ||
      !phoneNumber ||
      !birthday
    ) {
      setError("All fields are required.");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character."
      );
      return;
    }

    const phoneRegex = /^\+212\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setError("Invalid phone number.");
      return;
    }

    if (password !== cPassword) {
      setError("Passwords do not match.");
      return;
    }

    handleClientRegister(e);
  };

  const validatePassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const countryCode = "+212";
    const numberPart = inputValue.replace(countryCode, "");

    const cleanedNumberPart = numberPart.replace(/\D/g, "");

    if (cleanedNumberPart.length <= 9) {
      setPhoneNumber(countryCode + cleanedNumberPart);
      setError("");
    } else {
      setError("Invalid phone number.");
    }
  };
  const handleClientRegister = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await sendEmailVerification(user);
      setVerificationSent(true);
      handleEmailVerification(user);
    } catch (error) {
      console.error("Firebase error:", error);
      setError("Registration failed: " + error.message);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsSubmitting(false); // Enable the button again
    }
  };

  const handleEmailVerification = async (user) => {
    try {
      while (!user.emailVerified) {
        await user.reload();
      }

      if (user.emailVerified) {
        handleProfileSuggestion(user);
      } else {
        alert(
          "Please verify your email by clicking the link sent to your inbox."
        );
      }
    } catch (error) {
      alert("Error while checking email verification: " + error.message);
    }
  };

  const handleProfileSuggestion = async (user) => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          firstName,
          lastName,
          birthday,
          phoneNumber,
          email,
          role: "client",
          MProjects: 0,
          AProjects: 0,
          showEmail: true,
          showPhone: true,
        });
      }

      navigate("/client");
    } catch (error) {
      alert("An error occurred while saving user data: " + error.message);
    }
  };

  return (
    <div className="form-container-LR sign-up">
      {error && <div className="error-message">{error}</div>}
      {verificationSent && (
        <div className="success-message">
          Verification email sent! Please check your inbox.
        </div>
      )}
      <form onSubmit={handleAllgood}>
        <h1>Create Account</h1>
        <div className="social-icons">
          <span
            className="icon enabled"
            onClick={() => handleSocialSignIn(googleProvider, navigate)}
          >
            <BsGoogle />
          </span>
          <span
            href=""
            className="icon enabled"
            onClick={() =>
              handleSocialSignIn(facebookProvider, navigate)
            }
          >
            <BsFacebook />
          </span>
          <span
            href=""
            className="icon enabled"
            onClick={() => handleSocialSignIn(githubProvider, navigate)}
          >
            <BsGithub />
          </span>
          <span
            href=""
            className="icon enabled"
            onClick={() => handleSocialSignIn(yahooProvider, navigate)}
          >
            <FaYahoo />
          </span>
        </div>
        <span>or use your email for registration</span>
        <div className="last-side">
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
          />
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
          />
        </div>
        <div className="last-side">
          <input type="date" onChange={(e) => setBirthday(e.target.value)} />
          <input
            type="tel"
            value={phoneNumber}
            onChange={handleChange}
            placeholder="+212 "
            maxLength={13}
          />
        </div>
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="last-side">
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm password"
            onChange={(e) => setCPassword(e.target.value)}
          />
          {cPassword && password !== cPassword && (
            <div className="error-message">Passwords do not match.</div>
          )}
        </div>
        <button disabled={isSubmitting} type="submit">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUp;
