import React from 'react';

const DataDeletionInstructions = () => {
  return (
    <div className="data-deletion-instructions-container">
      <h1>Data Deletion Instructions</h1>
      <p>If you'd like to delete your account and all your personal data, follow these steps:</p>
      <ol>
        <li>Go to your profile settings.</li>
        <li>Click the "Delete Account" button.</li>
        <li>Confirm the deletion of your account and data.</li>
      </ol>
      <p>
        If you have any issues, contact us at{' '}
        <a href="mailto:support@yourapp.com">support@yourapp.com</a>.
      </p>
    </div>
  );
};

export default DataDeletionInstructions;
