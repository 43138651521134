import React, { useState } from "react";
import useCurrentUser from "../currentUser/currentuser";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { MDBModalTitle } from "mdb-react-ui-kit";
import "./deleteaccount.css";  // Corrected filename
import { db } from "../firebase";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase auth functions

const DeleteAccount = () => {
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const user = useCurrentUser(); // Move the hook here, not inside handleDeleteAccount

  const handleDeleteAccount = async () => {
    if (!user) {
      setError("No user found");
      return;
    }

    try {
      setIsDeleting(true);

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      await deleteDoc(userDoc);

      // Step 2: Delete the user from Firebase Authentication
      const auth = getAuth();
      await auth.currentUser.delete();  // Use the imported Firebase auth

      setSuccess("Your account and data have been successfully deleted.");
      setIsDeleting(false);

      // Optionally, sign out the user after deletion
      await auth.signOut();
    } catch (err) {
      setIsDeleting(false);
      setError("There was an issue deleting your account. Please try again.");
    }
  };

  return (
    <div className="delete-account-container">
      <h2>Delete Your Account</h2>
      <Button variant="danger" onClick={() => setShowModal(true)}>
        Delete Account
      </Button>

      {/* Modal for confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <ModalHeader closeButton>
          <MDBModalTitle>Confirm Account Deletion</MDBModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete your account? This action is irreversible.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteAccount}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Yes, Delete"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Success or error message */}
      {success && <Alert variant="success">{success}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

export default DeleteAccount;
