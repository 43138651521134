import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto p-6" style={{color: "white"}}>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700"><strong>Last Updated: 14/02/2025</strong></p>
      
      <h2 className="text-2xl font-semibold mt-6">1. Information We Collect</h2>
      <p>We collect the following types of information when you use our app:</p>
      <ul className="list-disc list-inside">
        <li><strong>Personal Information:</strong> Name, email, profile picture (via Facebook login).</li>
        <li><strong>Usage Data:</strong> Login times, interactions within the app.</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mt-6">2. How We Use Your Information</h2>
      <p>Your data is used to:</p>
      <ul className="list-disc list-inside">
        <li>Authenticate and manage accounts.</li>
        <li>Improve user experience.</li>
        <li>Ensure security and compliance.</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mt-6">3. Data Security</h2>
      <p>We take reasonable security measures to protect your information.</p>
      
      <h2 className="text-2xl font-semibold mt-6">4. Your Rights</h2>
      <p>You can access, update, or delete your account information at any time.</p>
      
      <h2 className="text-2xl font-semibold mt-6">5. Contact Us</h2>
      <p>If you have any questions, contact us at:</p>
      <p>Email: <strong>webweavesupp@gmail.com</strong></p>
      <p>Website: <strong>webweave.vercel.app</strong></p>
    </div>
  );
};

export default PrivacyPolicy;
