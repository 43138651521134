import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTypography,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";
import NotLoggedIn from "./notLoggedIn";
import Loading from "../loading";
import "./profiles.css";
import {
  Chart2,
  Chart4tip,
  DataProfile1,
  DataProfile2,
} from "../masterFL/charts";
import { Box, Divider } from "@chakra-ui/react";
import Project from "../projectList/project";

import ProfileHeader from "./profileHeader";
import useCurrentUser from "../currentUser/currentuser";
import EditProfile from "./editProfile";
import DeleteAccount from "../contents/deleteaccount";

function Profile() {
  const { user } = useAuth();
  const [userData, setUserData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [basicActive, setBasicActive] = useState("Information");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const userC = useCurrentUser();

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }
    const fetchProfile = () => {
      const docRef = doc(db, "users", user.uid); // Correct the reference here

      // Real-time listener for changes in the profile document
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setUserData(docSnap.data());
            setFirstName(userData.firstName);
            setLastName(userData.lastName);
          } else {
            setUserData(null);
          }
        },
        (error) => {
          console.error("Error fetching profile:", error.message);
          setLoading(false);
        }
      );

      return () => unsubscribe(); // Cleanup the listener
    };
    fetchProfile();
  }, [user]);
  // Real-time listener for projects
  useEffect(() => {
    const fetchProjects = () => {
      setLoading(true);
      const projectsQuery = query(
        collection(db, "projects"),
        orderBy("publicationDate", "desc")
      );

      // Real-time listener for changes in the projects collection
      const unsubscribe = onSnapshot(
        projectsQuery,
        (querySnapshot) => {
          const projectData = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().userId === userC.uid) {
              projectData.push({ id: doc.id, ...doc.data() });
            }
          });
          setProjects(projectData);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching projects:", error);
          setLoading(false);
        }
      );

      return () => unsubscribe(); // Cleanup the listener
    };

    fetchProjects();
  }, [userC.uid]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  if (loading) return <Loading />;

  if (error) return <div>Error: {error}</div>;

  if (!user) return <NotLoggedIn />;

  return (
    <div className="profileDiv">
      <MDBContainer className="profileContainer">
        <MDBCard className="profileCard">
          <ProfileHeader userData={userData} />
          <MDBCardBody className="profileBody">
            {userData.role !== "support" ? (
              <section className="section-1">
                <DataProfile1 role={userData.role} user={userData} />
                <Box className="notificationsDividerVertical">
                  <Divider orientation="vertical" borderColor="transparent" />
                </Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Chart4tip userOfProfile={userData} />
                </div>
                <DataProfile2 role={userData.role} user={userData} />
              </section>
            ) : (
              <section className="section-1">
                <DataProfile1 role={userData.role} user={userData} />
              </section>
            )}
            <section className="section-2">
              <div className="usersSection">
                <MDBTabs pills className="mb-3 tabsMDB profileTabsMDB">
                  <MDBTabsItem>
                    <MDBTabsLink
                      className="tabBtn"
                      onClick={() => handleBasicClick("Information")}
                      active={basicActive === "Information"}
                    >
                      Information
                    </MDBTabsLink>
                  </MDBTabsItem>
                  {userData.role === "client" ? (
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="tabBtn"
                        onClick={() => handleBasicClick("Projects")}
                        active={basicActive === "Projects"}
                      >
                        Projects
                      </MDBTabsLink>
                    </MDBTabsItem>
                  ) : (
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="tabBtn"
                        onClick={() => handleBasicClick("Review")}
                        active={basicActive === "Review"}
                      >
                        Review
                      </MDBTabsLink>
                    </MDBTabsItem>
                  )}
                  <MDBTabsItem>
                    <MDBTabsLink
                      className="tabBtn"
                      onClick={() => handleBasicClick("Settings")}
                      active={basicActive === "Settings"}
                    >
                      Settings
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      className="tabBtn"
                      onClick={() => handleBasicClick("Payment")}
                      active={basicActive === "Payment"}
                    >
                      Payment Method
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
                <MDBTabsContent className="tabsMDBContent">
                  <MDBTabsPane
                    className="paneProfile"
                    open={basicActive === "Review"}
                  >
                    review
                  </MDBTabsPane>
                  <MDBTabsPane
                    open={basicActive === "Information"}
                    className="paneProfile"
                  >
                    <MDBContainer className="profilePageContainer">
                      <span className="profilePageSpan-2">
                        <span className="spanInputsProfile">
                          <MDBInput
                            label="First name"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={`${userData.firstName}`}
                          />
                          <MDBInput
                            label="Last name"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={userData.lastName}
                          />
                        </span>
                        <span className="spanInputsProfile">
                          <MDBInput
                            label="Username"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={`${userData.firstName} ${userData.lastName}`}
                          />
                          <MDBInput
                            label="Phone number"
                            id="formControlDisabled"
                            type="text"
                            hidden={!userData.showPhone}
                            disabled
                            value={userData.number}
                          />
                        </span>
                        <span className="spanInputsProfile">
                          <MDBInput
                            label="E-mail"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={userData.email}
                          />
                          <MDBInput
                            label="Birthday"
                            id="formControlDisabled"
                            type="date"
                            disabled
                            value={userData.birthday}
                          />
                        </span>
                      </span>
                      <DeleteAccount />
                    </MDBContainer>
                  </MDBTabsPane>
                  <MDBTabsPane
                    open={basicActive === "Projects"}
                    className="paneProfile"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        margin: "1% 0",
                      }}
                    >
                      {projects.length !== 0 ? (
                        projects.map((project) => (
                          <Project
                            key={project.id}
                            clientN={`${project.firstName} ${project.lastName}`}
                            dateP={project.publicationDate}
                            stat={project.status}
                            leader={project.leader}
                            puid={project.userId}
                            body={project.body}
                            id={project.id}
                            minAmount={project.minAmount}
                            maxAmount={project.maxAmount}
                          />
                        ))
                      ) : (
                        <div className="NoProjectsDivProfile">
                          <h3>No Projects Available</h3>
                          <p>
                            It seems the user hasn't created any projects yet.
                          </p>
                        </div>
                      )}
                    </div>
                  </MDBTabsPane>
                  <MDBTabsPane
                    open={basicActive === "Settings"}
                    className="paneProfile"
                  >
                    <EditProfile userData={userData} user={user} />
                  </MDBTabsPane>
                  <MDBTabsPane
                    className="paneProfile"
                    open={basicActive === "Payment"}
                  >
                    {/* <CreditCardForm /> */}
                    {/* <Balance /> */}
                  </MDBTabsPane>
                </MDBTabsContent>
              </div>
            </section>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default Profile;
