import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Logout from "./Logout";
import Unauthorized from "./Unauthorized";
import ClientDashboard from "./roles/ClientDashboard";
import ProgrammerDashboard from "./roles/ProgrammerDashboard";
import GroupLeaderDashboard from "./roles/GroupLeaderDashboard";
import MasterDashboard from "./roles/MasterDashboard";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./forgotpassword";
import SupportMB from "./roles/support";
import Profile from "./profiles/Profile";
import ProfilePage from "./profiles/ProfilePage";
import NotFound from "./profiles/NotFound";
import Based from "./based/based";
import HomePage from "./based/homePage";
import Soon from "./based/soon";
import Test from "./profiles/Test";
import PrivacyPolicy from "./privacy-policy/prpo";
import DataDeletionInstructions from "./privacy-policy/deleteinstructions";

function App() {
  const [isAccessGranted, setIsAccessGranted] = useState(
    localStorage.getItem("accessGranted") === "true"
  );
  const [inputSequence, setInputSequence] = useState([]);

  const requiredSequence = ["t", "a", "h", "a", "2", "0", "0", "4", "a", "z", "e", "r", "t"];

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      setInputSequence((prevSequence) => {
        const newSequence = [...prevSequence, key];
        
        // Check if the new sequence matches the required sequence
        if (newSequence.join("") === requiredSequence.join("")) {
          setIsAccessGranted(true);
          localStorage.setItem("accessGranted", "true"); // Store access state
        } else if (!requiredSequence.join("").startsWith(newSequence.join(""))) {
          return []; // Reset if sequence is incorrect
        }

        return newSequence;
      });
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress); // Clean up the event listener
    };
  }, []);

  // Render either the access-restricted routes or the "Soon" page
  if (isAccessGranted) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/LRU" element={<Based />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:userId" element={<ProfilePage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/test" element={<Test />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-deletion-instructions" element={<DataDeletionInstructions />} />

          <Route element={<PrivateRoute allowedRoles={["client"]} />}>
            <Route path="/client" element={<ClientDashboard />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["support"]} />}>
            <Route path="/support" element={<SupportMB />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["master"]} />}>
            <Route path="/master" element={<MasterDashboard />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["programmer"]} />}>
            <Route path="/programmer" element={<ProgrammerDashboard />} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["groupleader"]} />}>
            <Route path="/groupleader" element={<GroupLeaderDashboard />} />
          </Route>
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Soon />} />
      </Routes>
    </Router>
  );
}

export default App;
